import React from 'react'
import styles from './OverviewItem.module.scss'
import classNames from 'classnames'
import { number, string, bool } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'
const ContentAdviceItem = (props) => {
  const {
    rating,
    title,
    strength,
    showDescription,
    description,
    hideDots,
    small
  } = props
  const ratingStyle = styles[`Rating-${rating}`]
  const isDark = useDarkContext()
  const colouredDots = strength || 0
  const greyDots = strength ? 5 - strength : 5
  const displayDots = !hideDots && title !== 'flashing/flickering lights'

  return (
    <div className={classNames(styles.Container, isDark && styles.dark)}>
      {displayDots && (
        <div className={styles.DotList}>
          {new Array(colouredDots).fill({}).map((_, i) => (
            <div key={i} className={classNames(styles.Dot, ratingStyle)} />
          ))}
          {new Array(greyDots).fill({}).map((_, i) => (
            <div key={i} className={classNames(styles.Dot, styles.GreyDot)} />
          ))}
        </div>
      )}
      <div
        className={classNames(
          styles.Title,
          showDescription && styles.BlackTitle,
          small && styles.SmallTitle
        )}
      >
        {title}
      </div>
      {showDescription && (
        <div className={classNames(styles.Description)}>{description}</div>
      )}
    </div>
  )
}
ContentAdviceItem.propTypes = {
  rating: string,
  title: string,
  description: string,
  strength: number,
  showDescription: bool,
  hideDots: bool,
  small: bool
}
export default ContentAdviceItem
