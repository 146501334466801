import React, { useState, useRef, useEffect } from 'react'
import { string } from 'prop-types'

import Type from '@/component/Primitive/Type'
import YouTubeEmbed from '@/component/Primitive/YouTubeEmbed'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'

import styles from './Trailer.module.scss'

import Hls from 'hls.js'

const Trailer = ({
  hlsStreamUrl,
  youtubeVideoId,
  headingStyles,
  videoWrapperStyles,
  title
}) => {
  const playerRef = useRef(null)
  const [playerType, setPlayerType] = useState('hls')
  const [videoVisible, setVideoVisible] = useState(false)

  useEffect(() => {
    let hls = null

    const initPlayer = () => {
      const player = playerRef.current
      if (Hls.isSupported()) {
        if (hls) {
          hls.destroy()
        }

        const newHls = new Hls({
          enableWorker: true
        })

        newHls.attachMedia(player)

        newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
          newHls.loadSource(hlsStreamUrl)
        })

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          setVideoVisible(true)
        })

        newHls.on(Hls.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                if (
                  !data.response ||
                  (data.response && data.response.code === 404) ||
                  (data.response && data.response.code === 400)
                ) {
                  setPlayerType(youtubeVideoId ? 'youtube' : null)
                  return
                }
                newHls.startLoad()
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                newHls.recoverMediaError()
                break
              default:
                initPlayer()
                break
            }
          }
        })
        hls = newHls
      } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
        // This handles iOS which support HLS without a plugin
        player.src = hlsStreamUrl
        player.addEventListener('loadedmetadata', () => {
          setVideoVisible(true)
        })
        player.addEventListener('error', () => {
          setPlayerType(youtubeVideoId ? 'youtube' : null)
        })
      }
    }

    if (playerType === 'hls') {
      initPlayer()
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [playerRef, hlsStreamUrl, playerType, youtubeVideoId])

  const sendMessage = (type, msg) => {
    parent.postMessage(
      {
        type,
        ...msg
      },
      '*'
    )
  }

  const shouldShowTitle =
    playerType === 'youtube' || (playerType === 'hls' && videoVisible)

  useEffect(() => {
    sendMessage('playerState', {
      videoVisible: shouldShowTitle
    })
  }, [playerType, shouldShowTitle])

  return (
    <div className={styles.TrailerContainer}>
      {shouldShowTitle && title && (
        <Type size="display1" color="teal" className={headingStyles} as="h2">
          {title}
        </Type>
      )}
      {['hls', 'youtube'].includes(playerType) && (
        <ResponsiveMedia
          style={{ display: videoVisible ? 'block' : 'none' }}
          className={videoWrapperStyles}
          ratio={9 / 16}
        >
          {playerType === 'hls' && (
            /* eslint-disable jsx-a11y/media-has-caption */
            <video
              style={{ display: videoVisible ? 'block' : 'none' }}
              ref={playerRef}
              controls
              width="100%"
              height="auto"
            />
          )}
          {playerType === 'youtube' && (
            <YouTubeEmbed videoId={youtubeVideoId} />
          )}
        </ResponsiveMedia>
      )}
    </div>
  )
}

Trailer.propTypes = {
  hlsStreamUrl: string,
  title: string,
  youtubeVideoId: string,
  headingStyles: string,
  videoWrapperStyles: string
}

export default Trailer
