const createQuery = (subQuery, additionalQuery = '') => {
  const query = `query Resource($url:String!${additionalQuery}) {
    instance(url:$url) {
      theme
      _id
      googleTagManagerId
      googleSiteVerificationCode
      name
      enabledClassifications
      navigation {
        id
        title
        type
        displayType
        section {
          fullUrlPath
        }
        url
        linkText
        images {
          thumbnail_3x2_438 {
            url
          }
        }
        parent
        order
      }
    }
    user {
      _id
      name
      email
      favourites {
        _id
      }
    }
    resource(url:$url) {
      __typename
      ...on Redirect {
        redirectUrl
      }
      ${subQuery}
    }
  }`
  return query
}
export default createQuery
