import React from 'react'

import styles from './Loading.module.scss'

const Loading = () => {
  return (
    <div className={styles.Loading}>
      <div className={styles.LoadingDots}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default Loading
